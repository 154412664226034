<template>
  <div>
    <el-row >
      <el-col :span="14" >
      <el-card>
      <el-descriptions :title="$t('msg.track.basicInfo')" column="4" style="font-size: smaller">
        <el-descriptions-item :label="$t('msg.track.id') + ':'">{{drayOrder.id}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.sslCarrier')+ ':'">{{drayOrder.sslCarrier}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.mbl')+ ':'">{{drayOrder.mbl}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.ctnrId')+ ':'">{{drayOrder.ctnrId}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.ctnrSize')+ ':'">{{drayOrder.ctnrSize}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.sealNumber')+ ':'">{{drayOrder.sealNumber}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.eta')+ ':'">{{drayOrder.vesselETA}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.weight')+ ':'">{{drayOrder.ctnrWeight}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.wtUOM')+ ':'">{{drayOrder.wtUOM}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.pod')+ ':'">{{drayOrder.port}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.track.attr')+ ':'">{{drayOrder.attr}}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :title="$t('msg.address.deliveryAddress')" column="6" style="font-size: smaller">
        <el-descriptions-item :label="$t('msg.address.streetOne')+ ':'">{{drayOrder.streetOne}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.address.streetTwo')+ ':'">{{drayOrder.streetTwo}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.address.city')+ ':'">{{drayOrder.city}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.address.state')+ ':'">{{drayOrder.state}}</el-descriptions-item>
        <el-descriptions-item :label="$t('msg.address.zip')+ ':'">{{drayOrder.zip}}</el-descriptions-item>
      </el-descriptions>
      </el-card>
      </el-col>
      <el-col :span="10">
        <el-card>
          <template #header>
            <span style="font-size: small">{{$t('msg.basicInfo.fileList')}}</span>
            <el-button type="primary" size="small" @click="fileUpload" style="float: right">{{ $t('msg.basicInfo.addFile') }}</el-button>
          </template>
          <el-table :data="basicInfoList" style="font-size: smaller">
            <el-table-column prop="refId" :label="$t('msg.track.id')" align="center" width="50px"></el-table-column>
<!--            <el-table-column prop="key" :label="$t('msg.basicInfo.key')" align="center" ></el-table-column>-->
            <el-table-column prop="value" :label="$t('msg.basicInfo.value')" align="center" width="250px"></el-table-column>
            <el-table-column prop="remark" :label="$t('msg.rate.remark')" align="center"></el-table-column>
            <el-table-column :label="$t('msg.rate.Operations')" align="center" width="150px">
              <template #default="{row}">
                <el-button type="primary" size="small" @click="imageView(row)" :icon="viewIcon"></el-button>
                <el-button type="danger" size="small" @click="delHandle(row)" :icon="delIcon" />
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
      <el-row >
        <el-col :span="12" >
          <el-card>
            <template #header>
              <span style="font-size: small">{{$t('msg.route.interimAP')}}</span>
              <span style="float: right;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalAP}}</span>
            </template>
            <el-table :data="interimAP" style="font-size: smaller">
              <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
              <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center" ></el-table-column>
              <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
              <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
                <template #default="scope">
                  <span >$ {{scope.row.rate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
                <template #default="scope">
                  <span >$ {{scope.row.finalAmount}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" size="small" style="float: right; margin-top: 20px; margin-bottom: 20px" disabled>{{ $t('msg.address.edit') }}</el-button>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <template #header>
              <span style="font-size: small">{{$t('msg.route.interimAR')}}</span>
              <span style="float: right;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalAR}}</span>
            </template>
            <el-table :data="interimAR" style="font-size: smaller">
              <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
              <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center" ></el-table-column>
              <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
              <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
                <template #default="scope">
                  <span >$ {{scope.row.rate}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
                <template #default="scope">
                  <span >$ {{scope.row.finalAmount}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" size="small" style="float: right; margin-top: 20px; margin-bottom: 20px" disabled>{{ $t('msg.address.edit') }}</el-button>
          </el-card>
        </el-col>
      </el-row>
    <el-card>
      <template #header>
        <span style="font-size: small">{{$t('msg.route.costRecord')}}</span>
      </template>
      <el-table :data="costTable" style="font-size: smaller">
        <el-table-column prop="id" :label="$t('msg.track.id')" align="center"></el-table-column>
        <el-table-column prop="bookId" :label="$t('msg.track.bookId')" align="center"></el-table-column>
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" align="center"></el-table-column>
        <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
        <el-table-column prop="chargeType" :label="$t('msg.account.chargeType')" align="center"></el-table-column>
        <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
        <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.rate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('msg.account.amount')" align="center">
          <template #default="scope">
            <span >$ {{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="editor" :label="$t('msg.rate.csopName')" align="center"></el-table-column>
        <el-table-column prop="remark" :label="$t('msg.rate.remark')" align="center"></el-table-column>
      </el-table>
      <el-button type="primary" size="small" style="float: right; margin-top: 20px; margin-bottom: 20px" disabled>{{ $t('msg.address.edit') }}</el-button>
    </el-card>
    <el-dialog title="File Upload" v-model="uploadShow" width="30%" center draggable>
      <el-form :model="uploadForm" enctype="multipart/form-data">
        <el-form-item label="Document Category" prop="docCategory" required style="width: 420px">
          <el-select v-model="uploadForm.docCategory" name="docCategory">
            <el-option label="Accounting Doc" value="Accounting Doc"></el-option>
            <el-option label="Legal Doc" value="Legal Doc"></el-option>
            <el-option label="License" value="License"></el-option>
            <el-option label="Other Record" value="Other Record"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Select File" prop="uploadFile">
          <el-upload class="upload-demo" ref="myUpload" action="" accept=".jpg,.png,.JPG,.PNG,.pdf,.PDF"
                     :limit="1" :auto-upload="false" :file-list="uploadForm.uploadList" :on-change="handleChange">
            <el-button type="primary">select file</el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png/pdf only.
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="float: right" @click="submitUpload">
        upload to server
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Delete, View } from '@element-plus/icons-vue'
import axios from 'axios'

export default defineComponent({
  name: 'drayOrderDetail',
  props: {
    myParam: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const drayOrder = ref({ id: 0, sslCarrier: '', mbl: '', ctnrId: '', ctnrSize: '', sealNumber: 'NA', vesselETA: '', ctnrWeight: 0, wtUOM: 'lb', port: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', attr: '' })
    const interimAP = ref([])
    const interimAR = ref([])
    const costTable = ref([])
    const basicInfoList = ref([])
    const totalAP = ref(0)
    const totalAR = ref(0)
    const uploadHandleURL = ref('')
    const myUpload = ref()
    const viewIcon = View
    const delIcon = Delete
    const uploadShow = ref(false)
    const uploadForm = ref({ refId: '', docCategory: '', uploadList: [] })
    const fileUpload = () => {
      uploadShow.value = true
      uploadHandleURL.value = process.env.VUE_APP_BASE + '/dray/imageUpload'
      console.log(props.myParam)
    }
    const handleChange = (file, fileList) => {
      uploadForm.value.uploadList = fileList
    }
    const submitUpload = () => {
      const formData = new FormData()
      formData.append('myParam', props.myParam)
      formData.append('docCategory', uploadForm.value.docCategory)
      formData.append('file', uploadForm.value.uploadList[0].raw)
      axios.post(uploadHandleURL.value, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        basicInfoList.value = response.data
        uploadForm.value = { refId: '', docCategory: '', uploadList: [] }
        uploadShow.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const imageView = (row) => {
      const param = props.myParam + '&fileName=' + row.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/imageView?' + param
      window.open(reqURL)
    }
    const delHandle = (row) => {
      const param = props.myParam + '&fileName=' + row.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/imageDel'
      axios.post(reqURL, param).then((response) => {
        basicInfoList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      const param = props.myParam
      const reqURL = process.env.VUE_APP_BASE + '/getMyDrayOrderDetail'
      axios.post(reqURL, param).then((response) => {
        drayOrder.value = response.data.drayOrder
        interimAP.value = response.data.interimAP
        interimAR.value = response.data.interimAR
        costTable.value = response.data.costTable
        basicInfoList.value = response.data.basicInfoList
        totalAP.value = getSum(interimAP.value)
        totalAR.value = getSum(interimAR.value)
      }).catch((response) => {
        console.log(response)
      })
    })
    function getSum(list) {
      let totalSum = 0
      for (let n = 0; n < list.length; n++) {
        totalSum = totalSum + list[n].finalAmount
      }
      return totalSum
    }
    return { viewIcon, delIcon, myUpload, imageView, delHandle, handleChange, submitUpload, uploadHandleURL, uploadShow, drayOrder, interimAP, interimAR, costTable, totalAP, totalAR, uploadForm, basicInfoList, fileUpload }
  }
})
</script>
